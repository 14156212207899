<template>
    <div class="messageDetails page-container" v-loading="loading">
        <BreadcrumbBack parent-title="消息管理" title="消息详情" back-path="/messageManage"/>
        <div class="adminContent" v-if="isAdmin">
            <div class="title">消息详情</div>
            <ul class="list">
                <li><span>消息类型</span>
                    <p>{{ messageType.find(i => i.code === detailData.type)?.type }}</p>
                </li>
                <li><span>消息标题</span>
                    <p>{{ detailData.title }}</p>
                </li>
                <li><span>内容</span>
                    <p style="white-space: pre;">{{ detailData.content }}</p>
                </li>
                <li><span>接收方式</span>
                    {{ detailData.receivingMode.map(item => {
                        return {IN_STATION_MESSAGE: '站内消息', MAIL_MESSAGE: '邮件消息'}[item]
                    }).join(',') }}
                </li>
                <li><span>接收人</span>
                    <p class="ellipsis">{{   detailData.receivingAccount === 'PART' ?
                        '部分用户：' + (detailData.receivingAccountUsername?.join(','))
                        :'全体用户'
                        }}</p>
                </li>
                <li><span>创建时间</span>
                    <p>{{ moment(detailData.createdAt).format('YYYY-MM-DD HH:mm:ss') }}</p>
                </li>
            </ul>
        </div>
        <div class="content" v-if="!isAdmin">
            <div class="title">
                <div class="type">
                    【{{ detailData.type }}】{{ detailData.title }}
                </div>
                <div class="time">{{ moment(detailData.createdAt).format('YYYY-MM-DD HH:mm:ss') }}</div>
            </div>
            <div class="text">{{ detailData.content }}</div>
        </div>
    </div>
</template>
<script>
import BreadcrumbBack from "@/views/components/BreadcrumbBack";
import moment from 'moment'
import {messageType} from "@/utils/constant";
import {getUnreadCount} from "@/utils/message";

export default {
    name: 'messageDetails',
    components: {BreadcrumbBack},
    computed: {
        isAdmin () {
            return true
        }
    },
    data() {
        return {
            detailData: {},
            loading: false,
            messageType
        }
    },
    created() {

        if (this.$route.query.bid) {
            this.getDetail()
        }
    },
    methods: {
        moment,
        getDetail() {
            this.$postJson('/message/info/' + this.$route.query.bid).then((res) => {
                this.detailData = res.data
                getUnreadCount()
            })
        },
    },
}
</script>
<style lang="scss" scoped>
.messageDetails {
    padding: 24px 32px;
    background: #fff;

    .adminContent {
        .title {
            line-height: 40px;
            font-size: 14px;
            font-weight: bold;
            color: #31363c;
        }

        .list li {
            display: flex;
            margin-bottom: 15px;

            span {
                width: 80px;
                color: #888;
                display: inline-block;
            }

            p {
                flex: 1;
                color: #444;
                word-break: break-all;
                text-align: left;
            }
        }
    }

    .content {
        background: #fff;
        margin-top: 20px;
        font-size: 14px;

        .title {
            padding: 20px 0;
            border-bottom: 1px solid #dcdddd;

            .type {
                font-size: 24px;
                text-align: center;
            }

            .time {
                text-align: center;
                margin-top: 10px;
            }
        }

        .text {
            padding: 20px;
            word-break: break-all;
        }
    }
}
</style>
